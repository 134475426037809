import React, { useContext, useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import Slide from "react-reveal/Slide"
import Pulse from "react-reveal/Pulse"

import "./index.scss"
import Video from "../components/video/video"
import Fade from "react-reveal/Fade"

// import VideoLightboxButton from "../components/video/video-lightbox-button";

import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { SlideContent } from "../components/slide-content/slide-content"
import { SlideContent3cols } from "../components/slide-content/slide-content-3cols"
import { SlideContent1col } from "../components/slide-content/slide-content-1col"
import { HeaderHighLights } from "../components/layout/header/header-highlights"
import FeaturedCards from "../components/featured-cards/featured-cards"
import VideoThumbnail from "../components/video/video-thumbnail"
import CtaButton from "../components/cta-button/cta-button"
import { navigate } from "gatsby"
import BubbleQuote from "../components/bubble-quote/bubble-quote"
import { Slogan } from "../components/slogan/slogan"
import { SloganFlipper } from "../components/slogan/slogan-flipper"
import { SlideContentBackdrop } from "../components/slide-content/slide-content-backdrop"
import { CarouselHome } from "../components/carousel-home/carousel-home"
import { AboutUsSection } from "../components/about-us/about-us-section"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"
import {
  GlobalStateProvider,
  showModalContext,
  useGlobalState,
} from "../utils/globalStateProvider"
// import { SlideContentBackdrop } from "../components/slide-content/slide-content-backdrop";

import { NegliChatbot } from "negligencias-chatbot"
import { sendEvent } from "../utils/analyticsService"
import { ContactWaysBanner } from "../components/contact-ways-banner/contact-ways-banner"
import ReactWhatsappButton from "react-whatsapp-button"

export default function Home() {
  // const [state, myDispatch] = useGlobalState()

  const onChatEnd = ({ steps, values }) => {
    sendEvent(`Chatbot END`, `Chatbot DEADEND values: ${values}`)
  }

  return (
    <GlobalStateProvider>
      <React.Fragment>
        <SEO title="Negligencias de abogados" />
        <Layout pageId="home">
          {/* <HeaderHighLights /> */}
          {/* <SloganFlipper></SloganFlipper> */}

          <CarouselHome />

          <NegliChatbot handleEnd={onChatEnd} />

          <ContactWaysBanner />

          <AboutUsSection />

          <FeaturedCards
            title="Casos reales de negligencias de abogados"
            type="casos-reales"
          />
          <FeaturedCards title="Blog" />

          <FullScreenModal />

          <ReactWhatsappButton
            onClick={() => {
              // Analytics
              sendEvent(`Whatsap Icon`)
            }}
            message="Hola Juan (experto en negligencias de abogados), soy ... , me gustaría más información sobre ..."
            countryCode="34"
            phoneNumber="677510724"
            animated
          />

          {/* <CtaButton
            onclick={() => {
              // setShowModal(true)
              // myDispatch({ showModal: true })
            }}
          >
            Open modal test
          </CtaButton> */}
        </Layout>
      </React.Fragment>
    </GlobalStateProvider>
  )
}
