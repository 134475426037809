import React from "react";

import "./slide-content.scss";
import "./slide-content-1col.scss";

export const SlideContent1col = ({ main, heading, highlight, cta }) => {
    return (
        <div className="slide-container onecol">
                <div className="main">{main}</div>
                <div className={`heading ${highlight? 'highlight':''}`}>{heading}</div>
                <div className="cta">{cta}</div>
        </div>
    );
};
