import React from "React"
import Fade from "react-reveal/Fade"

import "./contact-ways-banner.scss"
import { Link } from "gatsby"

export const ContactWaysBanner = () => {
  return (
    <Fade left>
      <div className="contact-ways-banner-container">
        {/* <h3>Formas de contacto</h3> */}
        <ul>
          <li key={"au-1"}>
            <a href="mailto: info@negligenciasdeabogados.net">
              <img src="/images/icons/email.svg" alt="email de contacto"></img>
              <strong>info@negligenciasdeabogados.net</strong>
            </a>
          </li>
          <li key={"au-2"} className="form-valoracion">
            <a href="https://forms.gle/xKKmBbTQSSTCDF6H7" target="_blank">
              <img
                src="/images/icons/form-valoracion.svg"
                alt="Envianos información de tu caso con unas simples preguntas"
              ></img>
              <strong>Cuéntanos de tu caso con unas simples preguntas</strong>
            </a>
          </li>
          <li key={"au-0"}>
            <Link to="/contacto/">
              <img
                src="/images/icons/form.svg"
                alt="formulario de contacto"
              ></img>
              <strong>Formulario de contacto</strong>
            </Link>
          </li>
        </ul>
      </div>
    </Fade>
  )
}
