import React from "React";
import Fade from "react-reveal/Fade";

import "./about-us-section.scss";

export const AboutUsSection = () => {
    return (
      <Fade left>
        <div className="about-us-section-container">
          <ul>
            <li key={"au-0"}>
              <img src="/images/icons/mistake.png" alt="error de abogado"></img>
              <div>
                <strong>¿Tu abogado ha cometido un error profesional?</strong>
                <p>Te ayudamos a reclamar contra tu abogado</p>
              </div>
            </li>
            <li key={"au-1"}>
              <img src="/images/icons/soluciones.png" alt="soluciones a errores de abogados"></img>
              <div>
                <strong>Te buscamos soluciones</strong>
                <p>Asesoramiento experto en negligencias de abogados</p>
              </div>
            </li>

            <li key={"au-2"}>
              <img src="/images/icons/confidencialidad.png" alt="confidencialidad"></img>
              <div>
                <strong>Confidencialidad en datos</strong>
                <p>Mínima solicitud de datos personales</p>
              </div>
            </li>
          </ul>
        </div>
      </Fade>
    )
};
