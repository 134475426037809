import React from "react";

import "./slide-content.scss";

export const SlideContent = ({ rightContent, leftContent }) => {
    return (
        <div className="slide-container grid grid--desktop-2cols grid--smartphone-1cols">
                <div className="left">{leftContent}</div>
                <div className="right">{rightContent}</div>
        </div>
    );
};
