import React from "react"

import "./slide-content.scss"
import "./slide-content-backdrop.scss"
import Img from "gatsby-image"

// import "./slide-content-1col.scss";

export const SlideContentBackdrop = ({
  backgroundGqlImage,
  overlayText,
  variant,
  cta,
  id,
}) => {
  return (
    <div
      className={`slide-container backdrop ${variant ? variant : ""}`}
      key={id}
    >
      <Img
        className="img-desktop"
        fixed={backgroundGqlImage.fixed}
        alt="-TODO-"
      />
      <Img
        className="img-mobile"
        fluid={backgroundGqlImage.fluid}
        alt="-TODO-"
      />

      <div className="overlay">
        <h1>{overlayText}</h1>
        {cta}
      </div>
    </div>
  )
}
