import React from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import "./featured-card-item.scss";

export default function FeaturedCardItem({ gqlImage, title, path }) {
    // console.log('JES gqlImage',gqlImage);
    return (
        <div className="featured-card-item-container" onClick={() => {
            navigate(path);
        }}>
            <Img className="img-desktop" fixed={gqlImage.fixed} alt={title} />
            <Img className="img-mobile" fixed={gqlImage.fixed} alt={title} />
            <h3>{title}</h3>
        </div>
    );
}
