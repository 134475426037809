import React, { useState } from "react"
import CtaButton from "../cta-button/cta-button"
import { SlideContentBackdrop } from "../slide-content/slide-content-backdrop"
import makeCarousel from "react-reveal/makeCarousel"
import Slide from "react-reveal/Slide"
import { navigate, useStaticQuery } from "gatsby"
import Modal from "react-responsive-modal"
import Video from "../video/video"
import { SlideContent1col } from "../slide-content/slide-content-1col"
import BubbleQuote from "../bubble-quote/bubble-quote"
import VideoThumbnail from "../video/video-thumbnail"
import { graphql } from "gatsby"

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CarouselUI = ({ position, handleClick, children }) => (
  <div className="carousel-container">
    {children}
    <div
      className="arrow left"
      onClick={handleClick}
      data-position={position - 1}
    >
      <FontAwesomeIcon icon={faChevronLeft} color="white" size="1x" />
    </div>
    <div
      className="arrow right"
      onClick={handleClick}
      data-position={position + 1}
    >
      <FontAwesomeIcon icon={faChevronRight} color="white" size="1x" />
    </div>
  </div>
)
const Carousel = makeCarousel(CarouselUI)

const slidesImages = [
  "diosa-justicia-2.png",
  "maquina-escribir-2.png",
  "martillo-juez-2.png",
  "lawyer-2.png",
  "novedad-dictamen-extrajudicial.png",
  "profesionales-slide.png",
]

export const CarouselHome = () => {
  const queryImagesResult = useStaticQuery(graphql`
    query {
      slidesResult: allImageSharp(
        filter: {
          fixed: {
            originalName: {
              in: [
                "diosa-justicia-2.png"
                "maquina-escribir-2.png"
                "martillo-juez-2.png"
                "lawyer-2.png"
                "novedad-dictamen-extrajudicial.png"
                "profesionales-slide.png"
              ]
            }
          }
        }
      ) {
        nodes {
          fixed(width: 1800) {
            originalName
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 1800) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { slidesResult } = queryImagesResult

  const slidesImagesGql = slidesImages.map(item => {
    return slidesResult.nodes.find(n => n.fixed.originalName === item)
  })

  const [openModal1, setOpenModal1] = useState(false)

  const onOpenModal1 = () => setOpenModal1(true)
  const onCloseModal1 = () => setOpenModal1(false)

  const [openModal2, setOpenModal2] = useState(false)

  const onOpenModal2 = () => setOpenModal2(true)
  const onCloseModal2 = () => setOpenModal2(false)

  const [openModal3, setOpenModal3] = useState(false)

  const onOpenModal3 = () => setOpenModal3(true)
  const onCloseModal3 = () => setOpenModal3(false)

  console.log("JES slidesImagesGql", slidesImagesGql)

  return (
    <React.Fragment>
      <Carousel defaultWait={0}>
        {/* <Carousel defaultWait={5000}> 
            negligencias-gatsby-site
            */}

        {slidesImagesGql[5] && (
          <Slide right>
            <SlideContentBackdrop
              backgroundGqlImage={slidesImagesGql[5]}
              variant="variant-2"
              id="44"
              overlayText="Nuevo servicio! Exclusivo para abogados"
              cta={
                <CtaButton
                  onclick={() => {
                    navigate("/servicios-profesionales/")
                  }}
                >
                  QUIERO SABER MÁS
                </CtaButton>
              }
            />
          </Slide>
        )}

        <Slide right>
          <SlideContent1col
            main={
              <VideoThumbnail
                overlayImgUrl="/images/Negligencias-Snapshot-video.png"
                openModalFn={onOpenModal3}
              />
            }
          ></SlideContent1col>
        </Slide>
        <Slide right>
          <SlideContent1col
            // heading={
            //     <BubbleQuote>
            //         ¿Cómo diablos reclamo a mi abogado?
            //     </BubbleQuote>
            // }
            main={
              <VideoThumbnail
                overlayImgUrl="https://i3.ytimg.com/vi/kHkTdlsPLAg/maxresdefault.jpg"
                openModalFn={onOpenModal2}
              />
            }
          ></SlideContent1col>
        </Slide>
        <Slide right>
          <SlideContentBackdrop
            backgroundGqlImage={slidesImagesGql[4]}
            variant="variant-2"
            id="4"
            overlayText="Novedad!    El dictamen será la solución extrajudicial más económica"
            cta={
              <CtaButton
                onclick={() => {
                  navigate("/dictamen-para-resolucion-extrajudicial/")
                }}
              >
                AVERÍGUALO CON NOSOTROS
              </CtaButton>
            }
          />
        </Slide>
        <Slide right>
          <SlideContentBackdrop
            backgroundGqlImage={slidesImagesGql[3]}
            id="3"
            overlayText="¿Sientes que algo no va bien con tu abogado?"
            cta={
              <CtaButton
                onclick={() => {
                  navigate("/nuestros-servicios/")
                }}
              >
                AVERÍGUALO CON NOSOTROS
              </CtaButton>
            }
          />
        </Slide>
        <Slide right>
          <SlideContentBackdrop
            backgroundGqlImage={slidesImagesGql[0]}
            id="0"
            overlayText="¿Sirve de algo poner queja en el Colegio de Abogados?"
            cta={
              <CtaButton
                onclick={() => {
                  navigate(
                    "/que-hacer-cuando-un-abogado-no-cumple-con-su-trabajo/"
                  )
                }}
              >
                AVERÍGUALO CON NOSOTROS
              </CtaButton>
            }
          />
          {/* <SlideContent1col
                    heading={
                        <BubbleQuote>
                            ¿Cuánto se puede ganar en un juicio de mala
                            praxis?
                        </BubbleQuote>
                    }
                    // heading={<h1>No estoy contento con mi abogado</h1>}
                    main={
                        <VideoThumbnail
                            overlayImgUrl="https://i.ytimg.com/vi/u1Hy-lNre9s/maxresdefault.jpg"
                            openModalFn={onOpenModal1}
                        />
                    }
                ></SlideContent1col> */}
        </Slide>
        <Slide right>
          <SlideContentBackdrop
            backgroundGqlImage={slidesImagesGql[2]}
            id="2"
            variant="variant-2"
            overlayText="¿Es viable reclamar a mi abogado?"
            cta={
              <CtaButton
                onclick={() => {
                  navigate("/cuestionario-autovaloracion/")
                }}
              >
                EMPEZAR CUESTIONARIO
              </CtaButton>
            }
          />
        </Slide>
        <Slide right>
          <SlideContentBackdrop
            backgroundGqlImage={slidesImagesGql[1]}
            id="1"
            variant="variant-1"
            overlayText=" ¿Cuánto se puede ganar en un juicio de mala praxis?"
            cta={
              <CtaButton
                onclick={() => {
                  navigate("/situacion-actual-reclamaciones-contra-abogados/")
                }}
              >
                AVERÍGUALO CON NOSOTROS
              </CtaButton>
            }
          />
        </Slide>

        {/* <Slide right>
                    <SlideContent1col
                        heading={
                            <h1>Cuestionario de Autovaloración gratuito!</h1>
                        }
                        highlight={true}
                        main={
                            <React.Fragment>
                                <img
                                    className="main"
                                    src="/images/inseguridad-2.jpg"
                                ></img>
                            </React.Fragment>
                        }
                        cta={
                            <CtaButton
                                onclick={() => {
                                    navigate("/cuestionario-autovaloracion/");
                                }}
                            >
                                COMENZAR
                            </CtaButton>
                        }
                    ></SlideContent1col>
                </Slide> */}
      </Carousel>

      <Modal open={openModal1} onClose={onCloseModal1} center>
        <Video
          videoSrcURL="https://www.youtube.com/embed/u1Hy-lNre9s?autoplay=1&mute=0"
          videoTitle="Consulta un experto por si puedes reclamar a tu abogado"
        />
      </Modal>

      <Modal open={openModal2} onClose={onCloseModal2} center>
        <Video
          videoSrcURL="https://www.youtube.com/embed/bXyjw4c6yi4?autoplay=1&mute=0"
          videoTitle="Errores de abogados"
        />
      </Modal>

      <Modal open={openModal3} onClose={onCloseModal3} center>
        <Video
          videoSrcURL="https://www.youtube.com/embed/SvdABFSnLdc?autoplay=1&mute=0"
          videoTitle="Algo no va bien con mi abogado"
        />
      </Modal>
    </React.Fragment>
  )
}
