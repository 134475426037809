import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import FeaturedCardItem from "./featured-card-item";

import "./featured-cards.scss";
import Fade from "react-reveal/Fade";

const getPostImages = (allMarkdownRemark) => {
    return allMarkdownRemark.edges.map(
        (item) => item.node.frontmatter.featuredImage.relativePath
    );
};
const getPostTitles = (allMarkdownRemark) => {
    return allMarkdownRemark.edges.map((item) => item.node.frontmatter.title);
};
const getPostPaths = (allMarkdownRemark) => {
    return allMarkdownRemark.edges.map((item) => item.node.frontmatter.path);
};

export default function FeaturedCards({ type, title }) {
    const results = useStaticQuery(graphql`
        query {
            allImageSharp {
                nodes {
                    fixed(width: 220, height: 150) {
                        originalName
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 800) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            imageResults2:allImageSharp {
                nodes {
                    fixed(width: 300, height: 150) {
                        originalName
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            blogResults:allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date }
                filter: { fileAbsolutePath: { regex: "/blog/" } }
                limit: 4
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            path
                            title
                            featuredImage {
                                relativePath
                            }
                        }
                    }
                }
            }
            casosResults:allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date }
                filter: { fileAbsolutePath: { regex: "/casos-reales/" } }
                limit: 4
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            path
                            title
                            featuredImage {
                                relativePath
                            }
                        }
                    }
                }
            }
        }
    `);


    const { blogResults, casosResults, allImageSharp, imageResults2 } = results;

    const resultsToShow = (type === 'casos-reales') ? casosResults: blogResults;

    const postImages = getPostImages(resultsToShow);
    // console.log("JES FeaturedCards resultsToShow", resultsToShow);
    // console.log(
    //     "JES FeaturedCards resultsToShow",
    //     getPostImages(resultsToShow)
    // );

    const image1 = imageResults2.nodes.find(
        (n) => n.fixed.originalName === postImages[0]
    );

    const image2 = imageResults2.nodes.find(
        (n) => n.fixed.originalName === postImages[1]
    );

    const image3 = imageResults2.nodes.find(
        (n) => n.fixed.originalName === postImages[2]
    );

    const image4 = imageResults2.nodes.find(
        (n) => n.fixed.originalName === postImages[3]
    );

    const titles = getPostTitles(resultsToShow);
    const paths = getPostPaths(resultsToShow);

    return (
      <div className={`featured-cards-container ${type}`}>
        <h2 className="title">{title}</h2>
        <ul className="grid grid--desktop-4cols grid--smartphone-1cols">
          {image1 && (
            <li key="fc-1">
              <Fade left>
                <FeaturedCardItem
                  gqlImage={image1}
                  title={titles[0]}
                  path={paths[0]}
                ></FeaturedCardItem>
              </Fade>
            </li>
          )}
          {image2 && (
            <li key="fc-2">
              <Fade left>
                <FeaturedCardItem
                  gqlImage={image2}
                  title={titles[1]}
                  path={paths[1]}
                ></FeaturedCardItem>
              </Fade>
            </li>
          )}
          {image3 && (
            <li key="fc-3">
              <Fade left>
                <FeaturedCardItem
                  gqlImage={image3}
                  title={titles[2]}
                  path={paths[2]}
                ></FeaturedCardItem>
              </Fade>
            </li>
          )}
          {image4 && (
            <li key="fc-4">
              <Fade left>
                <FeaturedCardItem
                  gqlImage={image4}
                  title={titles[3]}
                  path={paths[3]}
                ></FeaturedCardItem>
              </Fade>
            </li>
          )}
        </ul>
      </div>
    )
}
